<template>
  <div id="bbForm">
    <van-form ref="form" class="form" style="margin-bottom:65px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <custom-select ref="xn" required v-model="form.xn" placeholder="请选择学年" label="学年" :columns="dict.xj_nj" />
        <custom-select ref="xq" required v-model="form.xq" placeholder="请选择学期" label="学期" :columns="dict.xj_xqgl" />
        <custom-select ref="activityType" @change="selectClickHandle" required v-model="form.activityType" placeholder="请选择检查类型" label="检查类型" :columns="dict.evaluation_activity_type" />
        <van-field v-model="form.activityName" placeholder="请填写检查名称" label="检查名称" :required="true" :rules="[{ required: true  } ]" />
        <custom-select ref="reviewerNo" required @change="selectClickHandle2" v-model="form.reviewerNo" placeholder="请选择审核人" labelKey="name" valueKey="id" label="审核人" :columns="auditByInfo" />
        <van-field v-model="form.remark" rows="4" :required="true" :rules="[{ required: true  } ]" class="textarea-style" placeholder="请填写情况说明" arrow-direction="" label="情况说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <van-cell-group title="附件">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="fileList" miz="STU_PUNISH_RESOLVE" biz="storage" :multiple="1" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
      <!-- 负责学生-->
      <van-cell-group title="负责学生">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="item,index in form.stuChekInfos" :key="index" :title="item.perName" :value="item.perCode" :name="index">
            <div> <span class="collapse-title">院系:</span><span class="collapse-text">{{item.collName}}</span></div>
            <div> <span class="collapse-title">专业:</span><span class="collapse-text">{{item.specName}}</span></div>
            <div> <span class="collapse-title">班级:</span><span class="collapse-text">{{item.className}}</span></div>
            <van-button style="height:26px" round type="warning" plain hairline @click="familyDelete(index,item.id)">删除</van-button>
          </van-collapse-item>
        </van-collapse>
        <div class="form-addList-button">
          <van-button type="primary" native-type="button" size="small" @click="familyAdd">添加负责学生</van-button>
        </div>
      </van-cell-group>
      <van-cell-group title="负责教师" style="margin-bottom:64px">
        <van-collapse v-model="activeNames2">
          <van-collapse-item v-for="item,index in form.staffCheckInfos" :key="index" :title="item.perName" :value="item.perCode" :name="index">
            <div> <span class="collapse-title">院系:</span><span class="collapse-text">{{item.collName}}</span></div>
            <van-button style="height:26px" round type="warning" plain hairline @click="teacherDelete(index,item.id)">删除</van-button>
          </van-collapse-item>
        </van-collapse>
        <div class="form-addList-button">
          <van-button type="primary" native-type="button" size="small" @click="teacherAdd">添加负责教师</van-button>
        </div>
      </van-cell-group>
      <div class="submit-button shadow-style">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit()">提 交</van-button>
        </div>
      </div>
    </van-form>
    <!-- 负责学生 -->
    <van-popup v-model="familyInfoShow" @close="familyclose" closeable position="bottom" :style="{ height: '80%', backgroundColor:'#F5F5F5' }">
      <div class="wj-title">负责学生选择</div>
      <van-search class="wj-search" v-model="search" @input="onSearchHandle" placeholder="请输入学生学号或姓名检索" />
      <div class="wj-body">
        <van-cell v-for="(row,index) in stuData" :key="index" class="stu-card">
          <template #title>
            <span style="font-size:15px;font-weight:bold"> {{row. stuName +"  " +row. stuNo}}</span>
          </template>
          <template #label>
            <span style="font-size:14px;">{{ row.sqecName +'-'+  row.className}}</span>
          </template>
          <template #right-icon>
            <van-button class="table-edit-btn" @click="selectRow(row)" plain round size="small" type="primary">选择</van-button>
          </template>
        </van-cell>
      </div>
    </van-popup>
    <!-- 负责老师选择 -->
    <van-popup v-model="teacherInfoShow" @close="teacherClose" closeable position="bottom" :style="{ height: '80%', backgroundColor:'#F5F5F5' }">
      <div class="wj-title">负责老师选择</div>
      <van-search class="wj-search" v-model="search2" @input="onSearchHandle2" placeholder="请输入学生工号或姓名检索" />
      <div class="wj-body">
        <van-cell v-for="(row,index) in teacherData" :key="index" class="stu-card">
          <template #title>
            <span style="font-size:15px;font-weight:bold"> {{row.staffName +"  " +row.staffNo}}</span>
          </template>
          <template #label>
            <span style="font-size:14px;">{{row.unit && row.unit.name? row.unit.name : ""}}</span>
          </template>
          <template #right-icon>
            <van-button class="table-edit-btn" @click="selectRow2(row)" plain round size="small" type="primary">选择</van-button>
          </template>
        </van-cell>
      </div>
    </van-popup>
  </div>
</template>
<script>

import crudEvaluationActivity from '@/api/modules/evaluation/evaluationActivity'
import { getStuinfoNotPageSmall } from '@/api/modules/xj/stuInfo'
import fileUpload from "@/components/FileUpload";
import { getStaffInfosSmallNoPage } from '@/api/modules/xgdw/xgStaffInfo'
import { validCustom } from "@/utils/index";
import { getTermYear, getTerm } from '@/utils/index'

export default {
  name: "EvaluationActivityForm",
  dicts: ['evaluation_activity_type', 'xj_nj', 'xj_xqgl', 'xj_shzt'],
  components: { fileUpload },
  data() {
    return {
      form: {
        fileId: null,
        stuChekInfos: [],
        staffCheckInfos: [],
        id: null,
        activityName: null,
        activityType: null,
        activityTypeName: null,
        xn: null,
        xq: null,
        reviewerNo: null,
        reviewerName: null,
        fileId: null,
        approvalStatus: null,
        remark: null,
      },
      activeNames: [0],
      activeNames2: [0],
      isFamilyAdd: false,
      familyInfoShow: false,
      editIndex: 0,
      loading: false,
      auditByInfo: [],
      search: "",
      search2: "",
      stuData: [],
      refsh: true,
      fileList: [],
      teacherInfoShow: false,
      teacherData: [],
      search: "",
      customRuleFileds: ["xn", "xq", "activityType", "reviewerNo"]
    }
  },
  watch: {
    "dict.xj_xqgl": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.getTermAndYear(newval);
        }
      },
    },
  },
  created() {
    // 或者审核人最小权限
    crudEvaluationActivity.getAuditUserPage().then((res) => {
      this.auditByInfo = res
    })
    if (this.$route.query && this.$route.query.id) {
      crudEvaluationActivity.findById(this.$route.query.id).then((res) => {
        this.form = { ...res }
        if (res.fileId) {
          this.fileList = [res.fileId]
        }
        this.refsh = false
        this.$nextTick((e) => {
          this.refsh = true
        });
      })
    } else {
      this.getTermAndYear()
    }
  },
  methods: {
    selectClickHandle(val, data) {
      this.form.activityTypeName = data.label
    },
    selectClickHandle2(val, data) {
      this.form.reviewerName = data.name
    },
    getTermAndYear(xj_xqgl) {
      if (xj_xqgl && xj_xqgl.length > 0) {
        let termYear = getTermYear(
          xj_xqgl,
          (new Date().getMonth() + 1).toString()
        )
        let term = getTerm(
          xj_xqgl,
          (new Date().getMonth() + 1).toString()
        )
        this.form.xn = termYear
        this.form.xq = term.xqCode
      }
    },
    selectRow(stuInfo) {
      let stuData = {
        perCode: stuInfo.stuNo,
        perName: stuInfo.stuName,
        collName: stuInfo.collName,
        collNo: stuInfo.collNo,
        specName: stuInfo.sqecName,
        specCode: stuInfo.sqecCode,
        className: stuInfo.className,
        classNo: stuInfo.classNo,
        type: 1,
      }
      let stus = this.form.stuChekInfos.map((res) => res.perCode)
      if (stus.indexOf(stuData.perCode) > -1) {
        return
      } else {
        this.form.stuChekInfos.push(stuData)
      }
      this.familyclose()
    },
    selectRow2(teacher) {
      let teacherData = {
        perCode: teacher.staffNo,
        perName: teacher.staffName,
        collName: teacher.unit.name,
        collNo: teacher.unit.deptCode,
        type: 2,
      }
      let stus = this.form.staffCheckInfos.map((res) => res.perCode)
      if (stus.indexOf(teacherData.perCode) > -1) {
        return
      } else {
        this.form.staffCheckInfos.push(teacherData)
      }
      this.teacherClose()
    },
    uploadResponse(fileIds) {
      if (fileIds && fileIds[0]) {
        this.form.fileId = fileIds[0]
      }
    },
    teacherAdd() {
      this.teacherInfoShow = true;
    },
    teacherDelete() {
      this.form.staffCheckInfos.splice(index, 1);
    },
    teacherClose() {
      this.teacherInfoShow = false;
      this.teacherData = []
      this.search2 = ""
    },
    onSearchHandle2(queryString) {
      getStaffInfosSmallNoPage({ staffNo: queryString })
        .then((staffList) => {
          if (staffList && staffList.length) {
            this.teacherData = staffList
          } else {
            this.teacherData = []
          }
        })
    },
    onSearchHandle(queryString) {
      getStuinfoNotPageSmall({ stu: queryString }).then((stulist) => {
        if (stulist && stulist.length) {
          this.stuData = stulist
        } else {
          this.stuData = []
        }
      })
    },
    familyAdd() {
      this.familyInfoShow = true;
    },
    familyDelete(index, id) {
      this.form.stuChekInfos.splice(index, 1);
    },
    familyclose() {
      this.familyInfoShow = false;
      this.stuData = []
      this.search = ""
    },
    doAdd() {
      crudEvaluationActivity.add(this.form)
        .then((res) => {
          this.loading = false;
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.cancel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    doEdit() {
      this.loading = false;
      crudEvaluationActivity.edit(this.form)
        .then((res) => {
          this.loading = false;
          this.$notify({
            message: "操作成功",
            type: "success",
            duration: 2000,
          });
          this.cancel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    cancel() {
      this.form = {
        fileId: null,
        stuChekInfos: [],
        staffCheckInfos: [],
        id: null,
        activityName: null,
        activityType: null,
        activityTypeName: null,
        xn: null,
        xq: null,
        reviewerNo: null,
        reviewerName: null,
        fileId: null,
        approvalStatus: null,
        remark: null,
      }
      this.$router.go(-1)
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            if (!this.form.fileId) {
              this.$notify({
                type: "warning",
                message: "请上传附件材料",
                duration: 1500,
              });
              return;
            }
            if (!this.form.stuChekInfos && this.form.stuChekInfos.length === 0) {
              this.$notify({
                type: "warning",
                message: "请选择负责学生",
                duration: 1500,
              });
              return;
            }
            if (!this.form.staffCheckInfos && this.form.staffCheckInfos.length === 0) {
              this.$notify({
                type: "warning",
                message: "请选择负责教师",
                duration: 1500,
              });
              return;
            }
            // 验证通过数据提交
            this.$dialog
              .confirm({
                title: "提示",
                message: "你确定要进行提交操作吗？",
              })
              .then(() => {
                this.loading = true;
                if (this.$route.query && this.$route.query.id) {
                  this.doEdit();
                } else {
                  this.doAdd();
                }
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
  },
}
</script>

<style lang="scss" scoped>
#bbForm {
  .form {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }

  .wj-title {
    text-align: center;
    line-height: 55px;
    font-size: 17px;
    font-weight: bold;
    height: 40px;
    background-color: #fff;
  }
  .wj-search {
  }
  .stu-card {
    margin-top: 18px;
  }
  .wj-body {
    overflow-y: scroll;
    height: calc(80vh - 100px);
    padding: 0 18px;
  }
}
</style>